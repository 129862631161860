<template>
  <div class="mbanner">
    <div class="wrapper">
      <div class="mbanner-hd">
        <div class="cn"><h2>一站式大厅</h2></div>
        <div class="en">One-stop hall</div>
      </div>
      <div class="mbanner-bd mbanner-bd1">
        中国石家庄人力资源服务产业园一站式公共服务大厅坐落于人才城二楼，占地面积1000平方米，<br />
        以便民、高效、廉洁、规范为宗旨，推行一站式办公、一条龙服务、<br />
        并联式审批、阳光下作业、规范化管理的运行模式。<br />
        具备了人力资源经营性服务和公共服务集聚发展的功能，<br />
        实行“一门式”受理、办理，<br />
        为企业和个人提供优质、高效、便捷的人力资源服务。
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner {
  background-image: url(../../../assets/images/mbanner04.jpg);
}
</style>
