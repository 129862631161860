<template>
  <div class="yzsdt">
    <div class="wrapper">
      <ul class="yzsdt-list">
        <li class="l1"
            v-for="(item, key) in list"
            :key="item.id">
          <div class="yzsdt-box">
            <div class="yzsdt-hd">
              <img class="icon"
                   :src="
                  item.itemValue == 1
                    ? require('../../../assets/images/icon27.png')
                    : require('../../../assets/images/icon28.png')
                " />
              <h3>{{ item.itemText }}</h3>
              <p class="en">
                {{
                  item.itemValue == 1 ? "Talent Service" : "Corporate Services"
                }}
              </p>
            </div>
            <div class="yzsdt-bd">
              <ul>
                <li v-for="(childItem, index) in item.child"
                    :key="childItem.id"
                    :class="item.activeTab == index ? 'active' : ''"
                    @click="changeTab(index, key)">
                  {{ childItem.servicesTheme }}
                </li>
              </ul>
            </div>
            <div class="yzsdt-ft">
              <a @click="toRouter('apply',key)"
                 class="mbtn sbig orange">预约咨询</a>
            </div>
          </div>
        </li>

        <!-- <li class="swiper-slide" v-for="item in pictureList" :key="item.id">
          <a href="#">
            <img :src="item.pic" />
            <h3 class="tit">{{ item.title }}</h3>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      list: [],
    };
  },
  mounted () {
    this.getServiceList();
  },
  methods: {
    toRouter (routerName, key) {
      let query = {
        way: 2,
        cn: this.list[key].child[this.list[key].activeTab].servicesTheme,
        en: this.list[key].child[this.list[key].activeTab].english,
        id: this.list[key].child[this.list[key].activeTab].id,
      };
      console.log(query, this.list[key].child[this.list[key].activeTab], key, this.list[key].activeTab)
      // return
      this.newRouter('/' + routerName, query)
    },
    changeTab (index, itemValue) {
      // if (itemValue == "1") {
      //   this.activeTab = index;
      // } else {
      //   this.servicesTab = index + 1;
      // }
      this.list[itemValue].activeTab = index;
    },
    //企业展示
    async getServiceInfoList (type) {
      const result = await this.api
        .getServiceInfoList({ servicesType: type })
        .then((res) => {
          const {
            data: { result },
          } = res;
          const { records } = result || [];
          return records;
        });
      return result;
    },
    async getServiceList () {
      let arr = [];
      await this.api.getServiceList().then((res) => {
        const {
          data: { result },
        } = res;

        result.map(async (item) => {
          const childList = await this.getServiceInfoList(item.itemValue, item);
          console.log(7777, childList);
          const one = {
            activeTab: 0,
            ...item,
            child: childList,
          };
          console.log(555, one);
          arr.push(one);
          return one;
        });
      });
      console.log(arr, 'arrarrarr')
      this.list = arr;
    },
  },
};
</script>
<style scoped lang="scss"></style>
