<template>
  <div class="el_onestopHall">
    <banner />
    <introduce />
  </div>
</template>

<script>
import banner from "./banner";
import introduce from "./introduce";
export default {
  data () {
    return {
      msg: 'el_onestopHall',
    }
  },
  components: {
    banner,
    introduce,
  },
  created () {

  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
</style>
